
import Http from './Http'

export default {
    async getTransactions(data) {
        return await Http.get(`/payment/transactions?${data}`)
    },
    async getTransaction(transactionId) {
        return await Http.get(`/payment/transactions/${transactionId}`)
    },
    async updateTransaction(data) {
        return await Http.patch(`/payment/transactions`, data)
    },
    async createRefund(data) {
        return await Http.post(`/payment/refund`, data)
    },
}


